/**
 * icon types = 'default', 'rounded', 'outline', 'sharp'
 *
 */
module.exports = {
  theme: 'clockwork',
  config: {
    show_utility_bar: false
  },
  icons: {
    type: 'default',
    custom: {
      ic_tune: {
        viewbox: '0 0 24 24',
        content: '<path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"></path>'
      },
      ic_collections: {
        viewbox: '0 0 24 24',
        content: '<path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 9h-4v4h-2v-4H9V9h4V5h2v4h4v2z"></path>'

      }
    }
  }
}
